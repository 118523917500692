import { computed, watch } from 'vue'
import { xstore } from '@/store'
import {
  CONFIG_STORE_GETTERS,
  COMPANIES_STORE_GETTERS,
  INTEGRATIONS_STORE_ACTIONS,
  INTEGRATIONS_STORE_GETTERS,
  INTEGRATION
} from '@/enums'
import { Companies, Company, ServiceActivation, Integration } from '@/models'
import { useContext, useParamsContext, useInstanceHelpers, useAccountingSettings } from '@/composables'
import integrationTitle from '@/lib/integrationTitle'

export default function useIntegrations() {
  const { store } = useContext()
  const paramsContext = useParamsContext()

  const { t } = useInstanceHelpers()

  const { loadAccountingObjects } = useAccountingSettings()

  const companyId = computed(() => paramsContext.value?.companyId)

  const companies = computed<Companies>(() => {
    return xstore.getters[COMPANIES_STORE_GETTERS.COMPANIES] || {}
  })

  const activeCompany = computed<Company | undefined>(() => {
    return companies.value?.[companyId?.value || 0]
  })

  const activeIntegration = computed<Integration>(() => {
    return xstore.getters[INTEGRATIONS_STORE_GETTERS.ACTIVE_INTEGRATION] || {}
  })

  const serviceActivation = computed<ServiceActivation>(() => {
    return xstore.getters[INTEGRATIONS_STORE_GETTERS.SERVICE_ACTIVATION] || {}
  })

  const isEmailAllowed = (enteredEmail: string): Boolean => {
    let { mailDomain } = xstore.getters[CONFIG_STORE_GETTERS.CONFIG]
    if (enteredEmail.includes(mailDomain)) {
      return false
    }
    return true
  }

  const getActiveIntegration = async (companyId: any) => {
    if (!companyId) return
    return await store.dispatch(INTEGRATIONS_STORE_ACTIONS.GET_ACTIVE_INTEGRATION, companyId)
  }

  const checkServiceActivationState = async (companyId: any) => {
    if (!companyId) return
    return await store.dispatch(INTEGRATIONS_STORE_ACTIONS.CHECK_SERVICE_ACTIVATION_STATE, companyId)
  }

  const serviceActivationWarning = computed(() => {
    if (!activeCompany.value?.companyRoles?.length) return ''
    if (serviceActivation.value?.state !== 'pending') return ''
    const partnerId = serviceActivation.value?.partnerId || ''
    let integrationName = ''
    if (partnerId) {
      integrationName = integrationTitle({ id: partnerId }, activeCompany.value?.countryCode)
    }
    return t('integrationNotActive').replace('$software', integrationName)
  })

  const confirmServiceActivation = (companyId: any, code: any, accepted: Boolean) => {
    if (!companyId || !code) return
    return store.dispatch(INTEGRATIONS_STORE_ACTIONS.CONFIRM_SERVICE_ACTIVATION, { companyId, code, accepted })
  }

  const isExtRefIntegration = computed(() => {
    const integrationIds: INTEGRATION[] = [
      INTEGRATION.STANDARDBOOKS,
      INTEGRATION.ONE_S,
      INTEGRATION.ODOO,
      INTEGRATION.JUMIS_PRO,
      INTEGRATION.JUMIS_START
    ]
    return integrationIds.includes(activeIntegration.value?.id as INTEGRATION)
  })

  const allowModifyingFwdMode = computed(() => {
    const integrationIds: INTEGRATION[] = [
      INTEGRATION.CUSTOM,
      INTEGRATION.ASTERI,
      INTEGRATION.BRIOX,
      INTEGRATION.DIRECTO,
      INTEGRATION.E_ARVELDAJA,
      INTEGRATION.EMAIL,
      INTEGRATION.ERPLYBOOKS,
      INTEGRATION.EMCE_XML,
      INTEGRATION.FITEK,
      INTEGRATION.FIKS,
      INTEGRATION.INTAXO,
      INTEGRATION.MERIT,
      INTEGRATION.MONEO,
      INTEGRATION.MSBC,
      INTEGRATION.ODOO,
      INTEGRATION.SAF,
      INTEGRATION.SIMPLBOOKS,
      INTEGRATION.SMARTACCOUNTS,
      INTEGRATION.STANDARDBOOKS,
      INTEGRATION.TELEMA,
      INTEGRATION.JUMIS_PRO,
      INTEGRATION.VALIDIUS,
      INTEGRATION.VISMA_FIVALDI,
      INTEGRATION.VISMA_NETVISOR,
      INTEGRATION.HANSAWORLD
    ]
    return integrationIds.includes(activeIntegration.value?.id)
  })

  const allowOnlyReports = computed(() => {
    const integrationIds: INTEGRATION[] = [
      INTEGRATION.MONEO,
      INTEGRATION.JUMIS_START,
      INTEGRATION.ZALKTIS,
      INTEGRATION.VISMA_FIVALDI_CSV
    ]
    return integrationIds.includes(activeIntegration.value?.id)
  })

  watch(
    () => companyId.value,
    async (id, oldId) => {
      if (id !== undefined && id !== oldId) {
        const integration = await getActiveIntegration(id)
        if (integration?.configuration?.accountingEntriesEnabled) {
          loadAccountingObjects()
        }
      }
    }
  )

  return {
    activeIntegration,
    allowModifyingFwdMode,
    allowOnlyReports,
    isExtRefIntegration,
    serviceActivationWarning,
    serviceActivation,
    checkServiceActivationState,
    confirmServiceActivation,
    isEmailAllowed,
    getActiveIntegration
  }
}
